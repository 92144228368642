<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.legal_act }}</h4>
    <div class="admin-navbar align-right">
      <router-link to="/admin/law/create" class="action-button">{{ $lang.app.create }}</router-link>
    </div>
    <div class="admin-table">
      <table>
        <thead>
        <tr>
          <th class="key">{{ $lang.app.heading }}</th>
          <th>{{ $lang.app.file }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="lawItem in law" :key="lawItem.id">
          <td class="key">{{ $lang.$translate({ru: lawItem.titleRu, kg: lawItem.titleKg}) }}</td>
          <td>{{ lawItem.filename }}</td>
          <td>
            <div class="action-buttons">
              <router-link :to="`/admin/law/${lawItem.id}`"
                           class="action-button" :title="$lang.app.view"><i class="bi-info-square"></i></router-link>
              <router-link :to="`/admin/law/${lawItem.id}/edit`"
                           class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i></router-link>
              <button class="action-button" :title="$lang.app.delete" @click="deleteRequest(lawItem.id)"><i class="bi-x-square"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <DeleteModal v-if="deleteId"
                 @close="deleteId = null"
                 @delete="deleteResource"
    />
  </div>
</template>
<script>
import DeleteModal from '../../../components/admin/DeleteModal'
export default {
  components: {
    DeleteModal
  },
  data() {
    return {
      law: [],
      deleteId: null,
    }
  },
  watch: {
    '$route'() {
      this.fetchResources()
    }
  },
  methods: {
    fetchResources() {
      this.$axios.get(`/legal-act/list`).then(({data}) => {
        this.law = data
      })
    },
    deleteRequest(id) {
      this.deleteId = id
    },
    deleteResource() {
      this.$axios.delete(`/legal-act/delete/${this.deleteId}`).then(() => {
        this.deleteId = null
        this.fetchResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      })
    }
  },
  mounted() {
    this.fetchResources()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
</style>